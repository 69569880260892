import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FirstPage } from './pages/firstPage';
import { BridgePage } from './pages/bridgePage';
import { SecondPage } from './pages/secondPage';
import { ThirdPage } from './pages/thirdPage';
//import { EndingPage } from './pages/endingPage';

export const App = () => {
  const [visiblePage, setVisiblePage] = useState(0);
  const pageRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    // 스크롤 위치를 초기화하여 첫 번째 항목 보이기
    if (pageRefs[0]?.current) {
      pageRefs[0].current.scrollIntoView({ behavior: 'smooth' });
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = pageRefs.findIndex((ref) => ref.current === entry.target);
            if (index !== -1) setVisiblePage(index);
          }
        });
      },
      { threshold: 0.5 } // 50% 이상 보일 때 로드
    );

    pageRefs.forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => {
      pageRefs.forEach((ref) => {
        if (ref.current) observer.unobserve(ref.current);
      });
    };
  }, []);


  return (
    <Container>
      <Page
        ref={pageRefs[0]}
        initial={{ opacity: 0.2, y: 0 }}
        animate={visiblePage === 0 ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <FirstPage visiblePage={visiblePage} setVisiblePage={setVisiblePage} nextSectionRef={pageRefs[1]} />
      </Page>
      <Page
        ref={pageRefs[1]}
        initial={{ opacity: 0.5, y: 0 }}
        animate={visiblePage === 1 ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1 }}
      >
        <BridgePage visiblePage={visiblePage} setVisiblePage={setVisiblePage} nextSectionRef={pageRefs[2]} />
      </Page>
      <Page
        ref={pageRefs[2]}
        initial={{ opacity: 0.5, y: 0 }}
        animate={visiblePage === 2 ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1 }}
      >
        <SecondPage visiblePage={visiblePage} setVisiblePage={setVisiblePage} nextSectionRef={pageRefs[3]} />
      </Page>
      <Page
        ref={pageRefs[3]}
        initial={{ opacity: 0.5, y: 0 }}
        animate={visiblePage === 3 ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1 }}
      >
        <ThirdPage visiblePage={visiblePage} />
      </Page>
      {/*<Page
        ref={pageRefs[4]}
        initial={{ opacity: 0.5, y: 0 }}
        animate={visiblePage === 4 ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1 }}
      >
        <EndingPage visiblePage={visiblePage} setVisiblePage={setVisiblePage} nextSectionRef={pageRefs[4]} />
      </Page>*/}
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
`;

const Page = styled(motion.div)`
  height: 100vh;
  scroll-snap-align: start;
`;
