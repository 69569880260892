// FirstPage.jsx
import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import trophy from '../images/trophy.gif';
import applanding from '../images/applanding.png';
import atopy from '../images/atopy.gif';
import bottomarrow from '../images/bottomarrow.gif';


export const FirstPage = ({ visiblePage, setVisiblePage, nextSectionRef }) => {
    const handleScroll = () => {
        setVisiblePage(1); // 페이지 상태 업데이트
        nextSectionRef.current.scrollIntoView({ behavior: 'smooth' }); // 부드럽게 스크롤
      };
    return (
      <Container>
        <Content>
            <TitleContainer>
              <Title
                initial={{ opacity: 0, y: "5rem" }}
                animate={visiblePage === 0 ? { opacity: 1, y: "0rem" } : {}}
                transition={{ duration: 2, ease: 'easeOut' }}
              >
                가려움 추적기
              </Title>
              <Subtitle
                initial={{ opacity: 0, y: "5rem" }}
                animate={visiblePage === 0 ? { opacity: 1, y: "0rem" } : {}}
                transition={{ duration: 2, delay: 1, ease: 'easeOut' }}
              >
                <MiniIconImg
                    initial={{ opacity: 0, y: "-25rem" }}
                    animate={visiblePage === 0 ? { opacity: 1, y: "0rem" } : {}}
                    transition={{ duration: 1, delay: 1, ease: 'easeOut' }}
                    src={atopy}
                    alt="아토피로 고통 받는 피부"
                />
                가려움 관리로 더 행복한 삶을 만드세요
              </Subtitle>
              <EmphasisText
                initial={{ opacity: 0, y: "5rem" }}
                animate={visiblePage === 0 ? { opacity: 1, y: "0rem" } : {}}
                transition={{ duration: 2, delay: 1.5, ease: 'easeOut' }}
                >가려움 추적기는 단순한 기록 도구가 아닙니다</EmphasisText>
             <Subtitle
                initial={{ opacity: 0, y: "5rem" }}
                animate={visiblePage === 0 ? { opacity: 1, y: "0rem" } : {}}
                transition={{ duration: 2, delay: 1.5, ease: 'easeOut' }}
             >
                <AwardText>
                    <MiniIconImg
                        initial={{ opacity: 0, y: "-25rem"  }}
                        animate={visiblePage === 0 ? { opacity: 1, y: "0rem" } : {}}
                        transition={{ duration: 1, delay: 1.5, ease: 'easeOut' }}
                        src={trophy}
                        alt="2024년 환경부 환경 데이터 공모전 대상"
                    />
                    24년 환경부 환경데이터공모전 대상
                </AwardText>
             </Subtitle>
            <IconImg
                initial={{ opacity: 0}}
                animate={visiblePage === 0 ? { opacity: 1} : {}}
                transition={{ duration: 2, delay: 2, ease: 'easeOut' }}
                src={applanding}
                alt="가려움 추적기 앱 소개 화면"
            />
            </TitleContainer>
        </Content>
        <Content>
        <EmphasisText
            initial={{ opacity: 0, y: "50rem" }}
            animate={visiblePage === 0 ? { opacity: 1, y: "0rem" } : {}}
            transition={{ duration: 2, delay: 2.5, ease: 'easeOut' }}
            >가려움 원인을 파악하고,<br /> 해결 방안을 제시해드려요.</EmphasisText>
        </Content>
        <Content>
        <MidIconImg
            initial={{ opacity: 0 }}
            animate={visiblePage === 0 ? { opacity: 1 } : {}}
            transition={{ duration: 2, delay: 3.5, ease: 'easeOut' }}
            src={bottomarrow}
            onClick={()=>handleScroll()}
        />
        </Content>
      </Container>
    );
  };

// 스타일드 컴포넌트들
const Container = styled.div`
  display: block;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: rgba(107, 78, 197, 1.0);
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const Title = styled(motion.h1)`
  font-size: 3rem;
  color: #eee;
  margin-bottom: 1rem;
  @media (max-width: 960px) {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;

const Subtitle = styled(motion.p)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #ccc;
  @media (max-width: 960px) {
    font-size: 0.7rem;
  }
`;

const EmphasisText = styled(motion.div)`
  font-size: 1.1rem;
  color: #eee;
  font-weight: 600;
  text-align: center;
  @media (max-width: 960px) {
    font-size: 0.8rem;
  }
`;

const AwardText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 400;
  @media (max-width: 960px) {
    font-size: 0.7rem;
  }
`;

const IconImg = styled(motion.img)`
  overflow: hidden; /* 둥근 모서리 밖의 내용 제거 */
  width: 12rem;
`;

const MiniIconImg = styled(motion.img)`
  overflow: hidden; /* 둥근 모서리 밖의 내용 제거 */
  width: 1.5rem;
`;

const MidIconImg = styled(motion.img)`
  overflow: hidden; /* 둥근 모서리 밖의 내용 제거 */
  width: 3rem;
  cursor: pointer;
`;