// FirstPage.jsx
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import { motion } from 'framer-motion';
import bomi_run from '../images/bomi_run.gif';
import hyeongjoo from '../images/hyeongjoo.png';
import yumi from '../images/yumi.png';
import sejin from '../images/sejin.png';
import seongyeon from '../images/seongyeon.png';


export const ThirdPage = ({ visiblePage }) => {
    const carouselItems = [hyeongjoo, yumi, sejin, seongyeon];
    const carouselNames = ["형주", "유미", "세진", "승연"];
    const carouselMentions = [
      "저희는 가려움 해결에\n 몰입하고 있어요.",
      "극심한 가려움 대신,\n행복을 전하고 싶어요!",
      "기술로 가려움을 해결하는 데\n깊은 관심이 있어요.",
      "저는 AI를 기반으로 개인화된 맞춤\n가려움 솔루션을 제공하고자 해요.",
    ];
  
    return (
      <Container>
        <Content>
          <TitleContainer>
            <Title
              initial={{ opacity: 0, y: "5rem" }}
              animate={visiblePage === 3 ? { opacity: 1, y: "0rem" } : {}}
              transition={{ duration: 2, ease: "easeOut" }}
            >
              저희 팀은요..
            </Title>
          </TitleContainer>
          <GridContainer
            initial={{ opacity: 0, y: "25rem" }}
            animate={visiblePage === 3 ? { opacity: 1, y: "0rem" } : {}}
            transition={{ duration: 2, delay: 2, ease: "easeOut" }}
          >
            {carouselItems.map((item, index) => (
              <GridItem key={index}>
                <GridImage src={item} alt={`Team member : ${carouselNames[index]}`} />
                <GridName>{carouselNames[index]}</GridName>
                <GridMention>{carouselMentions[index]}</GridMention>
              </GridItem>
            ))}
          </GridContainer>
        </Content>
      </Container>
    );
  };
  
  // 스타일드 컴포넌트들
  const Container = styled.div`
    display: block;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: rgba(147, 171, 215, 1.0);
  `;
  
  const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `;
  
  const TitleContainer = styled.div`
    text-align: center;
    margin-top: 2rem;
    heght: 3.5rem;
    width: 40rem;
    align-items: center;
    justify-content: center;
    @media (max-width: 960px) {
      heght: 2.5rem;
    }
  `;
  
  const Title = styled(motion.h1)`
    font-size: 3rem;
    color: #fff;
    @media (max-width: 960px) {
      font-size: 2rem;
    }
  `;
  
  
const GridContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  border-radius: 1.5rem;
  padding: 1rem; /* 내부 여백 */
  background: rgba(147, 171, 215, 1.0); /* 배경에 약간의 그라디언트 */
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1), 
              -4px -4px 10px rgba(255, 255, 255, 0.2); /* 입체감 그림자 */

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

  const GridItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  `;
  
  const GridImage = styled.img`
    width: 8vw;
    height: auto;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
  
    @media (max-width: 960px) {
      width: 8vw;
    }
  `;
  
  const GridName = styled.div`
    font-size: 0.9rem;
    font-weight: 600;
    color: #eee;
  `;
  
  const GridMention = styled.div`
    font-size: 0.7rem;
    color: #ddd;
    margin-top: 1rem;
    white-space: pre-wrap; /* 개행 문자 유지 */
    text-align: center;
  `;