// FirstPage.jsx
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import docs from '../images/docs.gif';
import flag from '../images/flag-transparent.gif';
import img1 from '../images/1.png';
import img2 from '../images/2.png';
import img3 from '../images/3.png';
import img4 from '../images/4.png';
import img5 from '../images/5.png';
import img6 from '../images/6.png';
import img7 from '../images/7.png';
import img8 from '../images/8.png';
import img9 from '../images/9.png';
import img10 from '../images/10.png';
import img11 from '../images/11.png';
import img12 from '../images/12.png';
import img13 from '../images/13.png';
import img14 from '../images/14.png';
import bottomarrow from '../images/bottomarrow.gif';


export const SecondPage = ({ visiblePage, setVisiblePage, nextSectionRef }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const carouselItems = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % carouselItems.length);
    }, 2000); // 2초마다 전환

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 interval 해제
  }, [carouselItems.length]);

  const handleScroll = () => {
    setVisiblePage(1); // 페이지 상태 업데이트
    nextSectionRef.current.scrollIntoView({ behavior: 'smooth' }); // 부드럽게 스크롤
  };

  return (
    <Container>
      <Content>
        <TitleContainer>
            <TitleDiv>
                <MiniIconImg
                    initial={{ opacity: 0, y: "-15rem" }}
                    animate={visiblePage === 2 ? { opacity: 1, y: "0rem" } : {}}
                    transition={{ duration: 0.5, delay: 1, ease: 'easeOut' }}
                    src={docs}
                    alt="가려움 추적기의 발자취와 히스토리"
                />
                <Title
                    initial={{ opacity: 0, y: "5rem" }}
                    animate={visiblePage === 2 ? { opacity: 1, y: "0rem" } : {}}
                    transition={{ duration: 2, ease: 'easeOut' }}
                >
                    우리가 걸어온 길
                </Title>
            </TitleDiv>
            <Subtitle
                initial={{ opacity: 0, y: "10rem" }}
                animate={visiblePage === 2 ? { opacity: 1, y: "0rem" } : {}}
                transition={{ duration: 2, delay: 1, ease: 'easeOut' }}
            >
                <TextDiv2
                    initial={{ opacity: 0, y: "10rem" }}
                    animate={visiblePage === 2 ? { opacity: 1, y: "0rem" } : {}}
                    transition={{ duration: 2, delay: 2, ease: 'easeOut' }}
                >
                    <TargetText><b>고객 문제에 깊이 다가가기</b></TargetText>
                    <TargetTextSmall>아토피피부염 등 다양한 피부질환을 가진 서포터즈, 엠버서더, 베타테스터와 함께 고객의 문제를 깊이 이해하며, 이를 바탕으로 지속적으로 평가하고 개선해 나가고 있습니다.</TargetTextSmall>
                    <TargetText><b>가려움 추적기의 신뢰</b></TargetText>
                    <TargetTextSmall>벤처기업확인서 인증, 연구개발전담부서 운영, 환경 데이터 공모전 수상, TEU MED4 의료 혁신가 프로그램 등을 통해 혁신성과 신뢰를 인정받으며, 고객에게 개인화된 솔루션을 제공하고 있습니다.</TargetTextSmall>
                </TextDiv2>
            </Subtitle>
            <Carousel>
                <CarouselTrack
                    currentSlide={currentSlide}
                    totalSlides={carouselItems.length}
                >
                    {carouselItems.map((item, index) => (
                        <CarouselItem key={index}>
                            <CarouselImage src={item} alt={`Slide ${index + 1}`} />
                        </CarouselItem>
                    ))}
                </CarouselTrack>
            </Carousel>
            <MidIconImg
                initial={{ opacity: 0 }}
                animate={visiblePage === 2 ? { opacity: 1 } : {}}
                transition={{ duration: 2, delay: 3, ease: 'easeOut' }}
                src={bottomarrow}
                onClick={()=>handleScroll()}
            />
        </TitleContainer>
      </Content>
    </Container>
  );
};

// 스타일드 컴포넌트들
const Container = styled.div`
  display: block;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: rgba(130, 114, 204, 1.0);
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 40rem;
  align-items: center;
  justify-content: center;
`;

const Title = styled(motion.h1)`
  font-size: 3rem;
  color: #eee;
  @media (max-width: 960px) {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled(motion.p)`
  font-size: 1.2rem;
  margin-right: 5%;
  margin-left: 5%;
  color: #eee;
  text-align: left;
  @media (max-width: 960px) {
    font-size: 1rem;
  }
`;

const TextDiv2 = styled(motion.div)`
    font-size: 1.2rem;
    margin-right: 5%;
    margin-left: 5%;
    color: #eee;
    text-align: left;
    @media (max-width: 960px) {
        font-size: 1rem;
    }
`;

const TargetText = styled.div`
  margin: 0.5rem;
  color: #ddd;
`;

const TargetTextSmall = styled.div`
  color: #ccc;
  margin: 0.5rem;
  padding-left: 1.5rem;
  font-size: 0.8rem;
  @media (max-width: 960px) {
    font-size: 0.7rem;
    font-weight: 600;
  }
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MiniIconImg = styled(motion.img)`
  overflow: hidden; /* 둥근 모서리 밖의 내용 제거 */
  width: 5rem;
  @media (max-width: 960px) {
    width: 3rem;
  }
`;

const Carousel = styled.div`
  margin: auto;
  padding: auto;
  overflow: hidden;
  position: relative;
  width: 40rem;
  height: 20rem; /* 캐러셀 높이 조정 */
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  @media (max-width: 960px) {
    width: 20rem;
    height: 12rem;
  }
`;

const CarouselTrack = styled.div`
  display: flex;
  transform: translateX(
    ${({ currentSlide, totalSlides }) =>
      -(100 / totalSlides) * currentSlide}%
  );
  transition: transform 0.8s ease-in-out;
  width: ${({ totalSlides }) => `${100 * totalSlides}%`};
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin: auto;
  padding: auto;
`;

const CarouselItem = styled.div`
  flex: 0 0 ${({ totalSlides }) => `${100 / totalSlides}%`};
  display: flex;
  margin: auto;
  padding: auto;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding: 1rem;
`;

const CarouselImage = styled.img`
  margin: auto;
  width: 10rem;
  object-fit: contain; /* 이미지 비율 유지하며 크기 조정 */
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 7rem;
  }
`;

const MidIconImg = styled(motion.img)`
  overflow: hidden; /* 둥근 모서리 밖의 내용 제거 */
  width: 3rem;
  cursor: pointer;
`;