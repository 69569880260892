// BridgePage.jsx
import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import temperature from '../images/temperature.gif';
import chart from '../images/chart.gif';
import trigger from '../images/trigger.gif';
import docs from '../images/docs.gif';
import sunny from '../images/sunny.gif';
import moisturizer from '../images/moisturizer.gif';
import bottomarrow from '../images/bottomarrow.gif';
import appstore from '../images/appstore.png';
import googleplay from '../images/googleplay.png';

export const BridgePage = ({ visiblePage, setVisiblePage, nextSectionRef }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleScroll = () => {
    setVisiblePage(1); // 페이지 상태 업데이트
    nextSectionRef.current.scrollIntoView({ behavior: 'smooth' }); // 부드럽게 스크롤
  };

  const carouselItems = [temperature, chart, trigger, docs, sunny, moisturizer];
  const carouselMentions = [
    <InfoContent
      >
        <InfoText>1. 나의 가려움의 원인을 발견해요</InfoText>
        <InfoSubText>
          가려움에 영향을 주는 <b>환경적 요인</b>
          <br />
          (온도, 습도, 대기질)과 <b>생활 습관</b>을
          <br />
          분석하여 문제를 더 잘 이해할 수 있어요
        </InfoSubText>
        <IconImg src={temperature} alt="환경적 요인 : 온도 습도 대기질" />
      </InfoContent>,
      <InfoContent>
      <InfoSubText>
        데이터 기반 접근으로 가려움의 패턴과
        <br />
        발생 원인을 명확히 파악해요
      </InfoSubText>
      <IconImg src={chart} alt="데이터 기반 접근 원인 파악" />
    </InfoContent>,
    <InfoContent

    >
      <InfoText>2. 나만의 가려움 경향성을 파악해봐요</InfoText>
      <InfoSubText>
        가려움이 심해지면 <b>트리거</b>(발작 용인)를
        <br />
        알고, 이를 효과적으로 회피하는
        <br />
         방법을 제공받아보세요
      </InfoSubText>
      <IconImg src={trigger} alt="가려움 요인 파악 및 회피 방법 제시"/>
    </InfoContent>,
    <InfoContent

    >
      <InfoSubText>
        <b>예방 중심의 관리</b>로 가려움이
        <br />
        악화되지 않도록 도와드려요
      </InfoSubText>
      <IconImg src={docs} alt="가려움 보고서 예방 관리"/>
    </InfoContent>,
    <InfoContent

    >
      <InfoText>3. 피부에 맞는 보습제 추천을 받아보세요</InfoText>
      <InfoSubText>
        현재 날씨와 피부 상태에
        <br />
        딱 맞는 보습제를 추천해,
        <br />
        피부 관리의 번거로움을 줄여드려요
      </InfoSubText>
      <IconImg src={sunny} alt="날씨 피부 상태 보습제 추천"/>
    </InfoContent>,
    <InfoContent

    >
      <InfoSubText>
        날씨 변화에 따른
        <br />
        맞춤형 솔루션 보습제 추천으로
        <br />
        효과적인 피부 보호를 도와드려요
      </InfoSubText>
      <IconImg src={moisturizer} alt="날씨 변화 보습제 추천 피부 보호 관리"/>
    </InfoContent>

  ]

  useEffect(() => {
    if(visiblePage===1){
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % carouselItems.length);
          }, 4000); // 2초마다 전환
      
          return () => clearInterval(interval); // 컴포넌트 언마운트 시 interval 해제
    } else {
        setCurrentSlide(0);
    }
  }, [visiblePage, carouselItems.length]);

  return (
    <Container>
      <Content>
        <TitleContainer>
          <Title
            initial={{ opacity: 0, y: "5rem" }}
            animate={visiblePage === 1 ? { opacity: 1, y: "0rem" } : {}}
            transition={{ duration: 1, ease: 'easeOut' }}
          >
            가려움 추적기로 누릴 수 있는 세 가지
          </Title>
          <InfoDiv>
          <Carousel>
                <CarouselTrack
                    currentSlide={currentSlide}
                    totalSlides={carouselItems.length}
                >
                    {carouselItems.map((item, index) => (
                        <CarouselItem key={index}>
                            <CarouselMention alt={`Slide ${index + 1}`} >
                              {carouselMentions[index]}
                            </CarouselMention>
                        </CarouselItem>
                    ))}
                </CarouselTrack>
            </Carousel>
          </InfoDiv>
          <AppBanner
            initial={{ opacity: 0, y: "25rem" }}
            animate={visiblePage === 1 ? { opacity: 1, y: "0rem" } : {}}
            transition={{ duration: 2, delay: 1, ease: "easeOut" }}
          >
                <AppLink
                    href="https://apps.apple.com/kr/app/%EA%B0%80%EB%A0%A4%EC%9B%80-%EC%B6%94%EC%A0%81%EA%B8%B0/id6736654359"
                    target="_blank"
                >
                    <AppImage src={appstore} width={"9rem"} alt="App Store" />
                </AppLink>
                <AppLink
                    href="https://play.google.com/store/apps/details?id=com.Delight.todacmvp&pcampaignid=web_share"
                    target="_blank"
                >
                    <AppImage src={googleplay} width={"10rem"} alt="Google Play" />
                </AppLink>
            </AppBanner>
        </TitleContainer>
      </Content>
    </Container>
  );
};

// 스타일드 컴포넌트
const Container = styled.div`
  display: block;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: rgba(107, 78, 197, 1.0);
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const Title = styled(motion.h1)`
  font-size: 2.5rem;
  color: #eee;
  margin-bottom: 1rem;
  @media (max-width: 960px) {
    font-size: 1.3rem;
  }
`;

const InfoDiv = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  gap: 10rem;
  padding-top: 1rem;

  & > * {
    scroll-snap-align: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;


const InfoContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: 30rem;
  padding: 1rem;
  @media (max-width: 960px) {
    width: 15rem;
  }
`;

const InfoText = styled.div`
  margin: 1.5rem;
  font-size: 1.5rem;
  color: #eee;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  @media (max-width: 960px) {
    font-size: 0.7rem;
    font-weight: 700;
  }
`;

const InfoSubText = styled.div`
  font-size: 0.9rem;
  color: #ccc;
  margin: 1rem;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  @media (max-width: 960px) {
    font-size: 0.6rem;
    font-weight: 600;
  }
`;

const IconImg = styled.img`
  width: 9rem;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
`;

const Carousel = styled.div`
  margin: auto;
  padding: auto;
  overflow: hidden;
  position: relative;
  width: 50rem;
  height: 20rem; /* 캐러셀 높이 조정 */
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  @media (max-width: 960px) {
    width: 20rem;
  }
`;

const CarouselTrack = styled.div`
  display: flex;
  transform: translateX(
    ${({ currentSlide, totalSlides }) =>
      -(100 / totalSlides) * currentSlide}%
  );
  transition: transform 0.8s ease-in-out;
  width: ${({ totalSlides }) => `${100 * totalSlides}%`};
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin: auto;
  padding: auto;
`;

const CarouselItem = styled.div`
  flex: 0 0 ${({ totalSlides }) => `${100 / totalSlides}%`};
  display: flex;
  margin: auto;
  padding: auto;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding: 1rem;
`;

const CarouselMention = styled.div`
  margin: auto;
  width: 40rem;
  object-fit: contain;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: 960px) {
    width: 17rem;
  }
`;

const MidIconImg = styled(motion.img)`
  overflow: hidden; /* 둥근 모서리 밖의 내용 제거 */
  width: 3rem;
  cursor: pointer;
`;

const pulseBackground = keyframes`
  0% {

    transform: scale(0.9);
  }
  50% {
    transform: scale(1); /* 크기가 커짐 */
  }
  100% {
    transform: scale(0.9); /* 원래 크기로 복귀 */
  }
`;

  const AppBanner = styled(motion.div)`
  display: flex;
  margin-top: 1rem;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  background-color: #ffffffcc; /* 기본 배경색 */
  z-index: 8;
  @media (max-width: 960px) {
    gap: 0.2rem;
    padding: 0.5rem;
  }
`;


const AppLink = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
`;

const AppImage = styled.img`
  width: ${(props)=>(props.width ? props.width : "10rem")};
  object-fit: contain;
  animation: ${pulseBackground} 2s infinite;
`;